<template>
  <v-container fluid>
    <v-card flat outlined class="pa-sm-4 wraperx" v-if="loading">
      <v-skeleton-loader type="table"></v-skeleton-loader>
    </v-card>
    <v-card flat outlined class="pa-sm-4 wraperx" v-else>
      <v-card-title class="primary--text">
        Repair History
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          depressed
          @click="openNewRepairDialog"
          v-if="
            $_checkPermission(
              this.auth.permissions,
              'Manage Vehicle Repairs',
              'Create'
            )
          "
        >
          <v-icon left> mdi-plus </v-icon> New Log
        </v-btn>
      </v-card-title>
      <v-card-title
        class="justify-center justify-sm-start"
        v-if="
          $_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Repairs',
            'Read All'
          ) || $_checkPermission(
            this.auth.permissions,
            'Manage Vehicle Repairs',
            'Read Sub'
          )
        "
      >
      <v-card outlined>
          <v-tabs
            v-model="tab"
            @change="changeView"
            background-color="transparent"
          >
            <v-tooltip bottom max-width="200">
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="1"
                  >My</v-tab
                >
              </template>
              <span>View your repair logs</span>
            </v-tooltip>
            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Repairs',
                  'Read Sub'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Repairs',
                  'Read Sub'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="3"
                  >Sub</v-tab
                >
              </template>
              <span>View repair logs of your subordinates</span>
            </v-tooltip>

            <v-divider
              vertical
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Repairs',
                  'Read All'
                )
              "
            ></v-divider>
            <v-tooltip
              bottom
              max-width="200"
              v-if="
                $_checkPermission(
                  this.auth.permissions,
                  'Manage Vehicle Repairs',
                  'Read All'
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-tab
                  style="
                    overflow-x: hidden !important;
                    word-break: keep-all;
                    text-overflow: ellipsis !important;
                    max-width: calc(50vw - 50px);
                    min-width: 0 !important;
                  "
                  v-bind="attrs"
                  v-on="on"
                  :tab-value="2"
                  >All</v-tab
                >
              </template>
              <span>View all repair logs</span>
            </v-tooltip>
          </v-tabs>
        </v-card>
      </v-card-title>
      <v-card-title class="primary--text">
        <v-text-field
          filled
          dense
          outlined
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        mobile-breakpoint="0"
        :headers="headers"
        :items="items"
        :search="search"
        :items-per-page="5"
      >
        <template
          v-slot:item.actions="{ item }"
          v-if="
            $_checkPermission(
              this.auth.permissions,
              'Manage Vehicle Repairs',
              'Delete'
            )
          "
        >
          <v-btn
            depressed
            small
            icon
            color="error"
            class="mx-0 px-0 mt-1"
            dark
            @click="
              selectedItem = item;
              deleteDialog = true;
            "
          >
            <v-icon small> mdi-trash-can-outline </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card>

    <!-- New Repair dialog -->
    <v-dialog
      v-if="
        $_checkPermission(
          this.auth.permissions,
          'Manage Vehicle Repairs',
          'Create'
        )
      "
      v-model="newDialog"
      persistent
      max-width="600"
      scrollable
    >
      <v-card outlined>
        <v-card-title class="text-h5"> New Repair History </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-12">
          <v-form ref="newRepairForm">
            <v-row>
              <v-col cols="12" sm="6" class="py-0">
                <v-menu
                  :close-on-content-click="false"
                  transition="scale-transition"
                  min-width="auto"
                  v-model="menu1"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      flat
                      outlined
                      label="Repair Date"
                      v-model="newItem.date"
                      :rules="[rules.required, rules.todayOrPastDate]"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="newItem.date"
                    color="primary"
                    no-title
                    @input="menu1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-skeleton-loader
                  type="image"
                  height="50"
                  v-if="dataLoading"
                ></v-skeleton-loader>
                <v-autocomplete
                  v-else
                  flat
                  v-model="newItem.vehicle_uuid"
                  :rules="[rules.required]"
                  :items="vehicles"
                  item-text="vehicle_no"
                  item-value="uuid"
                  label="Vehicle"
                  outlined
                  @change="changeVehicle"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  flat
                  outlined
                  type="number"
                  label="Meter Reading (km)"
                  v-model="newItem.meter_reading_km"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" class="py-0">
                <v-text-field
                  flat
                  outlined
                  type="number"
                  label="Amount (LKR)"
                  v-model="newItem.amount"
                  :rules="[rules.required]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" class="py-0">
                <v-textarea
                  flat
                  outlined
                  label="Remarks"
                  v-model="newItem.description"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-form>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="secondary" text @click="newDialog = false">
              Cancel
            </v-btn>
            <v-btn
              color="primary"
              text
              @click="addVehicleRepairX"
              :loading="newLoading"
            >
              Create
            </v-btn>
          </v-card-actions>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Delete vehicle repair dialog -->
    <v-dialog
      v-model="deleteDialog"
      persistent
      max-width="290"
      v-if="selectedItem"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this vehicle repair record? This
          action cannot be undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteVehicleRepairX(selectedItem)"
            :loading="deleteLoading"
          >
            Delete
          </v-btn>
          <v-btn color="primary" text @click="deleteDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import {
  addVehicleRepair,
  deleteVehicleRepair,
} from "@/services/vehicleRepairsService";

import { fetchAndStoreMyVehicleRepairs } from "@/services/myDataService";

import { fetchAndStoreVehiclesData } from "@/services/preloadDataService";

export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  watch: {
    //
  },
  data: () => ({
    menu1: false,
    tab: 1,
    dataLoading: false,
    rules: {},
    headers: [
      {
        text: "Date",
        align: "start",
        value: "date",
        width: 105,
      },
      { text: "Vehicle No", value: "vehicles.vehicle_no" },
      {
        text: "Meter Reading (km)",
        value: "meter_reading_km",
      },
      {
        text: "Amount (LKR)",
        value: "amount",
      },
      { text: "Description", value: "description", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    items: [],
    search: "",
    loading: false,
    newDialog: false,
    newLoading: false,
    deleteDialog: false,
    deleteLoading: false,
    selectedItem: null,
    newItem: {
      uuid: "",
      driver_uuid: "",
      date: "",
      amount: null,
      meter_reading_km: null,
      description: "",
      vehicle_uuid: "",
      vehicles: {},
      staff: {},
    },
    vehicles: [],
    selectedVehicle: "",
  }),
  async created() {
    this.rules = {
      required: (value) => !!value || "Required.",
      todayOrPastDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate <= today || "The date must be today or in the past.";
      },
    };
    this.getMyRepair();
  },
  mounted() {
    if (this.$route.query.new && this.$route.query.new == "true") {
      this.openNewRepairDialog();
    }
  },
  methods: {
    changeView() {
      if (this.tab == 2) {
        this.$router.push({
          name: "PageAllVehicleRepairList",
        });
      }
      if (this.tab == 3) {
        this.$router.push({
          name: "PageSubVehicleRepairList",
        });
      }
    },
    async openNewRepairDialog() {
      this.newDialog = true;
      this.dataLoading = true;
      this.vehicles = await fetchAndStoreVehiclesData();
      if (
        this.vehicles.find((element) => element.driver_uuid == this.auth.uuid)
      ) {
        this.newItem.vehicle_uuid = this.vehicles.find(
          (element) => element.driver_uuid == this.auth.uuid
        ).uuid;
        this.changeVehicle();
      }
      this.dataLoading = false;
    },
    async getMyRepair() {
      this.loading = true;
      this.items = await fetchAndStoreMyVehicleRepairs();
      this.loading = false;
    },
    async addVehicleRepairX() {
      if (this.$refs.newRepairForm.validate()) {
        this.newLoading = true;
        this.newItem.uuid = uuidv4();
        this.newItem.driver_uuid = this.auth.uuid;
        this.newItem.vehicles = this.vehicles.find(
          (vehicle) => vehicle.uuid == this.newItem.vehicle_uuid
        );
        this.newItem.staff = this.auth;
        let newRepairReturn = await addVehicleRepair(
          this.newItem.vehicle_uuid,
          this.newItem
        );
        this.newLoading = false;

        if (newRepairReturn == "success") {
          this.newDialog = false;

          this.$refs.newRepairForm.reset();
          this.getMyRepair();
        }
      }
    },
    async deleteVehicleRepairX(item) {
      this.deleteLoading = true;
      let deleteRepairReturn = await deleteVehicleRepair(item.vehicle_uuid, {
        uuid: item.uuid,
      });
      this.deleteLoading = false;

      if (deleteRepairReturn == "success") {
        this.deleteDialog = false;
        this.getMyRepair();
      }
    },
    changeVehicle() {
      if (this.newItem.vehicle_uuid) {
        this.selectedVehicle = this.vehicles.find(
          (obj) => obj.uuid === this.newItem.vehicle_uuid
        );
        this.newItem.meter_reading_km =
          this.selectedVehicle.current_meter_reading;
      } else {
        this.newItem.meter_reading_km = 0;
        this.selectedVehicle = {};
      }
    },
  },
};
</script>